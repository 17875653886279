<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div class="flex h-full">
    <div
      class="uinav-layer relative z-10 h-full bg-white p-12 pr-6"
      :class="[{
        'shadow-drawer': activeLevel < 2,
        'overflow-y-auto overflow-x-hidden': !active?.[1],
      }]"
    >
      <div class="flex h-16 items-start">
        <div class="w-20">
          <UiIcon
            name="close"
            :width="14"
            :height="14"
            class="inline-flex cursor-pointer"
            @click="closeNav"
          />
        </div>
        <div class="flex-1 border-b border-b-blue-100 pb-10">
          <NuxtLink
            :to="baseUrl"
            @click="closeNav"
          >
            <UiLogo />
          </NuxtLink>
        </div>
      </div>
      <div class="flex items-start">
        <div class="w-20 py-3 text-lg">
          <UiIcon
            v-if="activeLevel"
            name="arrow"
            :width="14"
            :height="14"
            class="inline-flex rotate-180 cursor-pointer"
            @click="onLevelBack"
          />
        </div>
        <div
          v-if="links?.length"
          class="flex-1"
        >
          <TransitionGroup
            name="list"
          >
            <ul
              v-show="!activeLevel"
              key="lista"
              class="block w-full"
            >
              <li
                v-for="parentLink in links"
                :key="parentLink.link.toString()"
              >
                <NuxtLink
                  v-slot="{ href }"
                  :to="parentLink.link.toString()"
                  custom
                >
                  <a
                    :href="href"
                    class="block border-b border-b-blue-100 py-3 text-lg font-bold text-primary"
                    @click.prevent="onNavigate(parentLink)"
                  >
                    {{ parentLink.title }}
                  </a>
                </NuxtLink>
              </li>
            </ul>

            <template
              v-for="(parentLinkLevel1, index) in links"
              :key="`first-${parentLinkLevel1.link}-${parentLinkLevel1.title}`"
            >
              <div
                v-show="active?.[0]?.level === index + 2"
                class="w-full border-b border-b-blue-100 py-3"
              >
                <NuxtLink
                  v-slot="{ href, navigate }"
                  :to="parentLinkLevel1?.link"
                  no-prefetch
                  custom
                >
                  <a
                    :href="href"
                    class="block pb-3 text-lg font-bold text-primary"
                    @click.prevent="() => { navigate(); closeNav(); }"
                  >
                    {{ parentLinkLevel1?.title }}
                  </a>
                </NuxtLink>

                <ul
                  v-if="parentLinkLevel1?.children?.length"
                  class="w-full"
                >
                  <li
                    v-for="childrenLinkLevel1 in parentLinkLevel1?.children"
                    :key="childrenLinkLevel1.link?.toString()"
                  >
                    <NuxtLink
                      v-slot="{ href }"
                      :to="childrenLinkLevel1.link?.toString()"
                      custom
                    >
                      <a
                        :href="href"
                        class="block py-px"
                        @click.prevent="onNavigate(childrenLinkLevel1, 1)"
                      >
                        {{ childrenLinkLevel1.title }}
                      </a>
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </template>
          </TransitionGroup>
        </div>
      </div>
    </div>

    <template
      v-for="(parentLinkLevel1, index) in links"
      :key="`first-${index}`"
    >
      <template v-if="parentLinkLevel1?.children?.length">
        <template
          v-for="childrenLinkLevel1 in parentLinkLevel1?.children"
          :key="childrenLinkLevel1.link?.toString()"
        >
          <template v-if="childrenLinkLevel1?.children">
            <Transition
              mode="out-in"
              name="slide-left-in-right-out"
            >
              <div
                v-show="active?.[1]?.level === index + 3 && active?.[1]?.uid === childrenLinkLevel1.uid"
                class="uinav-layer absolute left-[488px] top-0 z-[-1] block h-full overflow-auto bg-blue-200 p-12 pt-28 shadow-xl"
              >
                <div class="flex items-start">
                  <div class="w-20 py-3 text-lg">
                    <UiIcon
                      v-if="activeLevel"
                      name="arrow"
                      :width="14"
                      :height="14"
                      class="inline-flex rotate-180 cursor-pointer"
                      @click="onLevelBack(1)"
                    />
                  </div>
                  <div class="flex-1 border-b border-b-blue-100 py-3">
                    <NuxtLink
                      v-slot="{ href, navigate }"
                      no-prefetch
                      :to="childrenLinkLevel1?.link"
                      custom
                    >
                      <a
                        :href="href"
                        class="block pb-3 text-lg font-bold text-primary"
                        @click.prevent="() => { navigate(); closeNav(); }"
                      >
                        {{ childrenLinkLevel1.title }}
                      </a>
                    </NuxtLink>

                    <ul class="w-full">
                      <li
                        v-for="childrenLinkLevel2 in childrenLinkLevel1?.children"
                        :key="childrenLinkLevel2?.link as string"
                      >
                        <NuxtLink
                          v-slot="{ href }"
                          :to="childrenLinkLevel2?.link?.toString()"
                          custom
                        >
                          <a
                            :href="href"
                            @click.prevent="emit('navigate', childrenLinkLevel2)"
                          >
                            {{ childrenLinkLevel2.title }}
                          </a>
                        </NuxtLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Transition>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import UiLogo from '../UiLogo/UiLogo.vue'
import type { UiNavLink } from './UiNav.types'

// import { useUiNav } from './useUiNav'

interface UiNav {
  /**
   * Array of links
   */
  links: UiNavLink[]
  /**
   * Array of active breadcrumbs of links
   * Used as v-model
   */
  active: UiNavLink[]
  baseUrl?: string
}

const props = withDefaults(defineProps<UiNav>(), {
  links: () => [],
  active: () => [],
  baseUrl: '/',
})

const emit = defineEmits<{
  /** notify about new active link */
  (e: 'update:active', link: UiNavLink[]): void
  /** parent component should update navigation for current link */
  (e: 'update:navigation', link: UiNavLink): void
  /** use to perform navigation */
  (e: 'navigate', link: UiNavLink): void
  /** notify about close */
  (e: 'close'): void
}>()

const active = computed({
  get: () => props.active,
  set: value => emit('update:active', value),
})

const activeLevel = computed(() => active.value.length)
function onLevelBack(index = 0) {
  return active.value.length ? active.value.splice(index, active.value.length) : null
}

function onNavigate(link: UiNavLink, level = 0) {
  if (!link) {
    return
  }

  if (link?.children_count && !link?.children) {
    active.value?.push(link)
    return emit('update:navigation', link)
  }
  if (link?.children_count && link?.children) {
    active.value[level] = link
  }
  else {
    return emit('navigate', link)
  }
}

function closeNav() {
  emit('close')
}
</script>

<style lang="postcss">
.uinav-layer {
  width: 488px;
}
</style>
